<template>
  <div>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4">
                  <select
                    class="form-control"
                    v-model="cur_LocationID"
                    @change="get_product_list_delay"
                  >
                    <option value="">{{ $t("Select Store") }}</option>
                    <option
                      v-for="(dt, idx) in storelist"
                      :key="idx"
                      :value="dt.LocationID"
                    >
                      {{ dt.LocationID }} - {{ dt.LocationName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <select
                    class="form-control"
                    v-model="cur_department_IDn"
                    @change="department_change"
                  >
                    <option :value="0">{{ $t("Select Department") }}</option>
                    <option
                      v-for="(dt, idx) in departmentlist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.Name1 }} - {{ dt.Name2 }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <select
                    class="form-control"
                    v-model="cur_category_IDn"
                    @change="get_product_list_delay"
                  >
                    <option :value="0">{{ $t("Select Category") }}</option>
                    <option
                      v-for="(dt, idx) in cur_categorylist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.Name1 }} - {{ dt.Name2 }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4">
                  <b-form-input
                    class="form-control"
                    :placeholder="$t('PLU/Barcode/Name')"
                    v-model="cur_name"
                    @keyup="get_product_list_delay"
                  ></b-form-input>
                </el-col>
                <el-col :span="4">
                  <b-form-input
                    class="form-control"
                    :placeholder="$t('Serial Number')"
                    v-model="cur_sn_search"
                    @keyup="get_product_list_delay"
                  ></b-form-input>
                </el-col>
                <!-- el-col :span="4">
                  <el-button type="primary" class="mt-2" icon="el-icon-check" size="small" round @click="export">{{ $t('Export') }}</el-button>
                </el-col -->
              </el-row>
            </b-form>
          </b-card>
          <el-tabs
            v-model="cur_list_opt"
            @tab-click="list_opt_change">
            <el-tab-pane :label="$t('S/N Detail List')" name="detail">
              <b-card>
                <div></div>
                <p class="p-3" v-if="snlist">
                  <el-table
                    stripe
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="snlist"
                  >
                    <el-table-column
                      :label="$t('PLU')"
                      prop="PLU"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('Name')"
                      :formatter="name_formatter"
                    ></el-table-column>
                    <el-table-column
                      label="S/N"
                      prop="SN"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('In Date')"
                      prop="InboundDate"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('In #')"
                    >
                      <template slot-scope="scope">
                        <span v-if="scope.row.InboundNo">
                          <a href="#/s_n_management" @click="order_no_click(scope, 'in')" v-acl:SNManagementEdit.enable>
                            {{ scope.row.InboundNo }}
                          </a>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('From')"
                      prop="PurchaseFTitle"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('Out Date')"
                      prop="OutboundDate"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('Out #')"
                    >
                      <template slot-scope="scope">
                        <span v-if="scope.row.OutboundNo">
                          <a href="#/s_n_management" @click="order_no_click(scope, 'out')" v-acl:SNManagementEdit.enable>
                            {{ scope.row.OutboundNo }}
                          </a>
                        </span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      :label="$t('To')"
                      prop="SoldTTitle"
                    ></el-table-column>
                    <el-table-column
                      :label="$t('Store')"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.LocationID ? storelist[scope.row.LocationID].LocationName : "" }}
                      </template>
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="current_page"
                    :total-rows="product_total"
                    :per-page="per_page"
                  ></b-pagination>
                </p>
                <p class="p-3" v-else>
                  {{ $t("No Records") }}
                </p>
              </b-card>
            </el-tab-pane>
            <el-tab-pane :label="$t('S/N Cross Check List')" name="crosscheck">
              <b-card>
                <div></div>
                <p class="p-3" v-if="productlist">
                  <el-table
                    stripe
                    class="table-responsive table"
                    header-row-class-name="thead-light"
                    :data="productlist"
                    @row-click="row_clicked_event"
                  >
                    <el-table-column :label="$t('Location')" prop="LocationID">
                      <template v-slot="{ row }">
                        {{ storelist[row.LocationID] ? storelist[row.LocationID].LocationName : "" }}
                      </template>
                    </el-table-column>
                    <el-table-column label="PLU" prop="PLU"></el-table-column>
                    <el-table-column :label="$t('Name')">
                      <template v-slot="{ row }">
                        {{ row.Name1 }} [{{ row.Name2 }}]
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Qty')" prop="Qty"></el-table-column>
                    <el-table-column :label="$t('S/N Qty')">
                      <template v-slot="{ row }">
                        <span
                          v-if="row.SerialNumber && row.SerialNumber.length > 0"
                        >
                          {{ JSON.parse(row.SerialNumber).length }}
                        </span>
                        <span v-else>0</span>
                      </template>
                    </el-table-column>
                    <el-table-column :label="$t('Serial Number')">
                      <template slot-scope="scope">
                        <span v-if="scope.row.SerialNumber">
                          {{ JSON.parse(scope.row.SerialNumber).join(",") }}
                        </span>
                      </template>
                    </el-table-column>
                  </el-table>
                  <b-pagination
                    v-model="current_page"
                    :total-rows="product_total"
                    :per-page="per_page"
                  ></b-pagination>
                </p>
                <p class="p-3" v-else>
                  {{ $t("No Records") }}
                </p>
              </b-card>
            </el-tab-pane>
          </el-tabs>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="order-modal"
      id="order-modal"
      size="xl"
      title="Order Information"
      v-model="order_modal_show"
      ok-title="Close"
      ok-only
      @ok="order_modal_ok"
    >
      <el-tabs
        v-model="cur_order_opt_2"
        @tab-click="order_opt_change"
      >
        <el-tab-pane v-if="cur_order_label_0" :label="cur_order_label_0" name="order0">
          <span class="order-title" v-if="cur_order_0 && cur_order_0.OrderID">
            {{ $t("Order") + " # : " + cur_order_0.OrderID }}
          </span>
          <form>
            <el-row :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="order_cust_name_func(cur_order_type_0) + ' : '">
                  <b-form-input
                    :value="order_cust_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Store') + ' : '">
                  <b-form-input
                    :value="order_store_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Date') + ' : '">
                  <b-form-input
                    :value="order_date_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row class="mt-1" :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="$t('Subtotal') + ' : '">
                  <b-form-input
                    :value="order_subt_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total Tax') + ' : '">
                  <b-form-input
                    :value="order_tax_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total') + ' : '">
                  <b-form-input
                    :value="order_total_func(cur_order_type_0, cur_order_0)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
          <br>
            <div style="width: 100%; height: 34.7em; overflow-y: visible;">
              <el-table
                v-if="cur_order_product_list_0"
                stripe
                class="data-grid table-responsive table"
                header-row-class-name="thead-light"
                style="width: 100%; padding: 0; overflow-y: visible; height: 38.5em;"
                :data="cur_order_product_list_0"
              >
                <el-table-column :label="$t('PLU')" prop="PLU" align="left">
                </el-table-column>
                <el-table-column :label="$t('Name')" :formatter="name_formatter" align="left">
                </el-table-column>
                <el-table-column :label="$t('Unit')" :formatter="op_unit_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Price')" :formatter="op_price_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Qty')" :formatter="op_qty_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Subtotal')" :formatter="op_subt_formatter" align="right">
                </el-table-column>
              </el-table>
              <p class="p-3" v-else>
                {{ $t("No Records") }}
              </p>
            </div>
          </form>
        </el-tab-pane>
        <el-tab-pane v-if="cur_order_label_1" :label="cur_order_label_1" name="order1">
          <span class="order-title" v-if="cur_order_1 && cur_order_1.OrderID">
            {{ $t("Order") + " # : " + cur_order_1.OrderID }}
          </span>
          <br>
          <form>
            <el-row :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="order_cust_name_func(cur_order_type_1) + ' : '">
                  <b-form-input
                    :value="order_cust_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Store') + ' : '">
                  <b-form-input
                    :value="order_store_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Date') + ' : '">
                  <b-form-input
                    :value="order_date_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row class="mt-1" :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="$t('Subtotal') + ' : '">
                  <b-form-input
                    :value="order_subt_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total Tax') + ' : '">
                  <b-form-input
                    :value="order_tax_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total') + ' : '">
                  <b-form-input
                    :value="order_total_func(cur_order_type_1, cur_order_1)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
          <br>
            <div style="width: 100%; height: 34.7em; overflow-y: visible;">
              <el-table
                v-if="cur_order_product_list_1"
                stripe
                class="data-grid table-responsive table"
                header-row-class-name="thead-light"
                style="width: 100%; padding: 0; overflow-y: visible; height: 38.5em;"
                :data="cur_order_product_list_1"
              >
                <el-table-column :label="$t('PLU')" prop="PLU" align="left">
                </el-table-column>
                <el-table-column :label="$t('Name')" :formatter="name_formatter" align="left">
                </el-table-column>
                <el-table-column :label="$t('Unit')" :formatter="op_unit_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Price')" :formatter="op_price_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Qty')" :formatter="op_qty_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Subtotal')" :formatter="op_subt_formatter" align="right">
                </el-table-column>
              </el-table>
              <p class="p-3" v-else>
                {{ $t("No Records") }}
              </p>
            </div>
          </form>
        </el-tab-pane>
        <el-tab-pane v-if="cur_order_label_2" :label="cur_order_label_2" name="order2">
          <span class="order-title" v-if="cur_order_2 && cur_order_2.OrderID">
            {{ $t("Order") + " # : " + cur_order_2.OrderID }}
          </span>
          <br>
          <form>
            <el-row :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="order_cust_name_func(cur_order_type_2) + ' : '">
                  <b-form-input
                    :value="order_cust_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Store') + ' : '">
                  <b-form-input
                    :value="order_store_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Date') + ' : '">
                  <b-form-input
                    :value="order_date_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
            <el-row class="mt-1" :gutter="10">
              <el-col :span="8">
                <b-input-group :prepend="$t('Subtotal') + ' : '">
                  <b-form-input
                    :value="order_subt_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total Tax') + ' : '">
                  <b-form-input
                    :value="order_tax_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
              <el-col :span="8">
                <b-input-group :prepend="$t('Total') + ' : '">
                  <b-form-input
                    :value="order_total_func(cur_order_type_2, cur_order_2)"
                    disabled
                  ></b-form-input>
                </b-input-group>
              </el-col>
            </el-row>
          <br>
            <div style="width: 100%; height: 34.7em; overflow-y: visible;">
              <el-table
                v-if="cur_order_product_list_2"
                stripe
                class="data-grid table-responsive table"
                header-row-class-name="thead-light"
                style="width: 100%; padding: 0; overflow-y: visible; height: 38.5em;"
                :data="cur_order_product_list_2"
              >
                <el-table-column :label="$t('PLU')" prop="PLU" align="left">
                </el-table-column>
                <el-table-column :label="$t('Name')" :formatter="name_formatter" align="left">
                </el-table-column>
                <el-table-column :label="$t('Unit')" :formatter="op_unit_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Price')" :formatter="op_price_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Qty')" :formatter="op_qty_formatter" align="right">
                </el-table-column>
                <el-table-column :label="$t('Subtotal')" :formatter="op_subt_formatter" align="right">
                </el-table-column>
              </el-table>
              <p class="p-3" v-else>
                {{ $t("No Records") }}
              </p>
            </div>
          </form>
        </el-tab-pane>
      </el-tabs>
    </b-modal>

    <b-modal
      ref="my-modal"
      title="Product S/N Detail"
      id="sn_management_modal"
      size="xl"
    >
      <form v-if="cur_product">
        <el-row :gutter="10">
          <el-col :span="6">
            <b-input-group :prepend="$t('Store') + ' : '">
              <b-form-input
                :value="
                  cur_product.LocationID + ' - ' + cur_product.LocationName
                "
                disabled
              ></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Product') + ' : '">
              <b-form-input
                :value="cur_product.Name1 + ' - ' + cur_product.Name2"
                disabled
              ></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="4">
            <b-input-group prepend="PLU : ">
              <b-form-input :value="cur_product.PLU" disabled></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="4">
            <b-input-group :prepend="$t('Barcode')+' : '">
              <b-form-input
                :value="cur_product.Barcode"
                disabled
              ></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="4">
            <b-input-group :prepend="$t('Qty')+' : '">
              <b-form-input :value="cur_product.Qty" disabled></b-form-input>
            </b-input-group>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mt-3">
          <el-col :span="24">
            <span v-if="parseInt(cur_product.Qty) > cur_SN.length">
              <b-input-group size="sm" class="mr-1 product_sn" prepend="S/N:">
                <b-form-input v-model="cur_newSN"></b-form-input>
                <b-input-group-append>
                  <b-button
                    size="sm"
                    text="Button"
                    variant="primary"
                    @click="add_prod_sn()"
                    >Add</b-button
                  >
                </b-input-group-append>
              </b-input-group>
            </span>
            <el-tag
              v-for="(sn, idx) in cur_SN"
              :key="idx"
              closable
              :disable-transitions="false"
              @close="remove_product_sn(sn)"
            >
              {{ sn }}
            </el-tag>
          </el-col>
        </el-row>
      </form>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="form_submit">
          Update
        </button>
        <button type="button" class="btn btn-secondary" @click="close_my_modal">
          Close
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Tag,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Tabs,
  TabPane,
  Carousel,
  CarouselItem,
} from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
// import flatPicker from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
  data() {
    return {
      order_modal_show: false,

      cur_list_opt: "",
      // cur_order_opt: "",
      cur_order_opt_1: "",
      cur_order_opt_2: "",

      cur_order_label_0: "",
      cur_order_label_1: "",
      cur_order_label_2: "",
      cur_order_type_0: "",
      cur_order_type_1: "",
      cur_order_type_2: "",
      cur_order_0: null,
      cur_order_1: null,
      cur_order_2: null,
      cur_order_product_list_0: "",
      cur_order_product_list_1: "",
      cur_order_product_list_2: "",

      cur_product: null,
      cur_SN: [],
      cur_sn: [],

      departmentlist: null,
      categorylist: null,
      productlist: null,
      storelist: null,
      snlist: null,

      cur_categorylist: null,
      cur_department_IDn: 0,
      cur_category_IDn: 0,
      cur_LocationID: "",
      cur_name: "",
      cur_sn_search: "",
      cur_newSN: "",

      delay_timer: null,

      cur_product_IDn: 0,
      current_page: 1,
      product_total: 0,
      per_page: 20,

      token: "",
      user_id: 0,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_product_list_delay();
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    hasUtilityAuth,
    order_date_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? cur_order.InboundDate : "";
          case "OB":
            return cur_order ? cur_order.OutboundDate : "";
          case "PO":
            return cur_order ? cur_order.OrderDate.split(" ")[0] : "";
          case "PR":
            return cur_order ? cur_order.ReturnDate.split(" ")[0] : "";
          case "RE":
            return cur_order ? cur_order.ReceiveDate.split(" ")[0] : "";
          case "SO":
            return cur_order ? cur_order.OrderDate.split(" ")[0] : "";
          case "SR":
            return cur_order ? cur_order.ReturnDate.split(" ")[0] : "";
          case "SP":
          case "UP":
          case "IB":
          case "OB":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_cust_name_func (cur_order_type) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            switch (this.cur_sn.InboundOrderType) {
              case "PO":
              case "PR":
              case "RE":
                return this.$t("Vendor");
              case "SO":
              case "SR":
                return this.$t("Customer");
              default:
                return this.$t("Vendor");
            }
          case "OB":
            switch (this.cur_sn.OutboundOrderType) {
              case "PO":
              case "PR":
              case "RE":
                return this.$t("Vendor");
              case "SO":
              case "SR":
                return this.$t("Customer");
              default:
                return this.$t("Customer");
            }
          case "PO":
          case "PR":
          case "RE":
            return this.$t("Vendor");
          case "SO":
          case "SR":
            return this.$t("Customer");
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_cust_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? cur_order.PurchaseFTitle : "";
          case "OB":
            return cur_order ? cur_order.SoldTTitle : "";
          case "PO":
            return cur_order ? cur_order.PurchaseFName : "";
          case "PR":
            return cur_order ? cur_order.PurchaseFName : "";
          case "RE":
            return cur_order ? cur_order.PurchaseFName : "";
          case "SO":
            return cur_order ? cur_order.SoldToName : "";
          case "SR":
            // return cur_order ? cur_order.OrderID : "";\
            return "";
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_store_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? this.storelist[cur_order.LocationID].LocationName : "";
          case "OB":
            return cur_order ? this.storelist[cur_order.LocationID].LocationName : "";
          case "PO":
            return cur_order ? cur_order.ComTitle : "";
          case "PR":
            return cur_order ? cur_order.ComTitle : "";
          case "RE":
            return cur_order ? cur_order.ComTitle : "";
          case "SO":
            return cur_order ? cur_order.ComTitle : "";
          case "SR":
            // return cur_order ? cur_order.OrderID : "";
            return "";
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_subt_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? cur_order.SubTotal : "";
          case "OB":
            return cur_order ? cur_order.SubTotal : "";
          case "PO":
            return cur_order ? cur_order.SubTotal : "";
          case "PR":
            return cur_order ? cur_order.SubTotal : "";
          case "RE":
            return cur_order ? cur_order.SubTotal : "";
          case "SO":
            return cur_order ? cur_order.SubTotal : "";
          case "SR":
            return cur_order ? cur_order.SubTotal : "";
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_tax_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? cur_order.TotalTAX : "";
          case "OB":
            return cur_order ? cur_order.TotalTAX : "";
          case "PO":
            return cur_order ? cur_order.TotalTax : "";
          case "PR":
            return cur_order ? cur_order.TotalTax : "";
          case "RE":
            return cur_order ? cur_order.TotalTax : "";
          case "SO":
            return cur_order ? cur_order.TotalTax : "";
          case "SR":
            return cur_order ? cur_order.TotalTax : "";
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    order_total_func(cur_order_type, cur_order) {
      if (!cur_order_type) return "";
      switch (cur_order_type) {
          case "IB":
            return cur_order ? cur_order.TotalAmount : "";
          case "OB":
            return cur_order ? cur_order.TotalAmount : "";
          case "PO":
            return cur_order ? cur_order.TotalAmount : "";
          case "PR":
            return cur_order ? cur_order.TotalAmount : "";
          case "RE":
            return cur_order ? cur_order.TotalAmount : "";
          case "SO":
            return cur_order ? cur_order.TotalAmount : "";
          case "SR":
            return cur_order ? cur_order.TotalAmount : "";
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    op_unit_formatter (row, column) {
      return row.Unit ? row.Unit : "";
    },
    op_price_formatter (row, column) {
      let order_type = 
        this.cur_order_opt_2 == "order0" ? this.cur_order_type_0 :
        this.cur_order_opt_2 == "order1" ? this.cur_order_type_1 :
        this.cur_order_opt_2 == "order2" ? this.cur_order_type_2 :
        "";
      if (!order_type) return "";
      switch (order_type) {
          case "IB":
          case "PO":
          case "PR":
          case "RE":
            return row.UnitCost ? row.UnitCost : ""
          case "OB":
          case "SO":
          case "SR":
            return row.UnitPrice ? row.UnitPrice : ""
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    op_qty_formatter (row, column) {
      let order_type = 
        this.cur_order_opt_2 == "order0" ? this.cur_order_type_0 :
        this.cur_order_opt_2 == "order1" ? this.cur_order_type_1 :
        this.cur_order_opt_2 == "order2" ? this.cur_order_type_2 :
        "";
      if (!order_type) return "";
      switch (order_type) {
          case "PO":
            return row.OrderQty ? row.OrderQty : ""
          case "PR":
            return row.ReturnQty ? row.ReturnQty : ""
          case "RE":
            return row.ReceivedQty ? row.ReceivedQty : ""
          case "SO":
            return row.OrderQty ? row.OrderQty : ""
          case "SR":
            return row.ReturnQty ? row.ReturnQty : ""
          case "SP":
          case "UP":
          case "IB":
            return row.OrderQty ? row.OrderQty : ""
          case "OB":
            return row.OrderQty ? row.OrderQty : ""
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    op_subt_formatter (row, column) {
      let order_type = 
        this.cur_order_opt_2 == "order0" ? this.cur_order_type_0 :
        this.cur_order_opt_2 == "order1" ? this.cur_order_type_1 :
        this.cur_order_opt_2 == "order2" ? this.cur_order_type_2 :
        "";
      if (!order_type) return "";
      switch (order_type) {
          case "IB":
          case "PO":
          case "PR":
          case "RE":
            return row.TotalCost ? row.TotalCost : ""
          case "OB":
          case "SO":
          case "SR":
            return row.TotalPrice ? row.TotalPrice : ""
          case "SP":
          case "UP":
          case "ST":
          case "SV":
          default:
            return "";
      }
    },
    name_formatter(row, column) {
      if (row.Name1 && row.Name2) {
        return (row.Name1 ? row.Name1 : "") + (row.Name2 ? "(" + row.Name2 + ")" : "");
      } else if (row.ProductName1 && row.ProductName2) {
        return (row.ProductName1 ? row.ProductName1 : "") + (row.ProductName2 ? "(" + row.ProductName2 + ")" : "");
      } else {
        return "";
      }
    },
    order_no_click(scope, v = "in") {
      this.cur_sn = scope.row;
      this.cur_order_opt_1 = v;
      this.cur_order_opt_2 = "order0";
      
      // console.log("order_no_click", this.cur_order_opt_1, 
      //   this.cur_order_opt_1 == "in" ? this.cur_sn.InboundOrderType :
      //   this.cur_order_opt_1 == "out" ? this.cur_sn.OutboundOrderType :
      //   "");

      if (this.cur_order_opt_1 == "in") {
        switch (this.cur_sn.InboundOrderType) {
          case "IB":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
          case "OB":
            this.cur_order_label_0 = "";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
          case "PO":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "";
            break;
          case "RE":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "Receive";
            break;
          case "PR":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "PO Return";
            break;
          case "SO":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "SO";
            this.cur_order_label_2 = "";
            break;
          case "SR":
            this.cur_order_label_0 = "Inbound";
            this.cur_order_label_1 = "SO";
            this.cur_order_label_2 = "SO Return";
            break;
          default:
            this.cur_order_label_0 = "";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
        }
      } else if (this.cur_order_opt_1 == "out") {
        switch (this.cur_sn.OutboundOrderType) {
          case "IB":
            this.cur_order_label_0 = "";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
          case "OB":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
          case "PO":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "";
            break;
          case "RE":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "Receive";
            break;
          case "PR":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "PO";
            this.cur_order_label_2 = "PO Return";
            break;
          case "SO":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "SO";
            this.cur_order_label_2 = "";
            break;
          case "SR":
            this.cur_order_label_0 = "Outbound";
            this.cur_order_label_1 = "SO";
            this.cur_order_label_2 = "SO Return";
            break;
          default:
            this.cur_order_label_0 = "";
            this.cur_order_label_1 = "";
            this.cur_order_label_2 = "";
            break;
        }
      } else {
        this.cur_order_label_0 = "";
        this.cur_order_label_1 = "";
        this.cur_order_label_2 = "";
      }

      this.cur_order_type_0 = "";
      this.cur_order_type_1 = "";
      this.cur_order_type_2 = "";
      this.cur_order_0 = null;
      this.cur_order_1 = null;
      this.cur_order_2 = null;
      this.cur_order_product_list_0 = "";
      this.cur_order_product_list_1 = "";
      this.cur_order_product_list_2 = "";

      this.get_order_list(function(that) {
        that.order_modal_show = true;
        // that.cur_order_opt = that.cur_order_opt_2;
      });
    },
    order_modal_ok (event) {
      if (event) event.preventDefault();
      this.order_modal_show = false;
    },
    list_opt_change(tab) {
      this.get_product_list_delay();
    },
    order_opt_change(tab) {
      this.cur_order_opt_2 = tab.name;
      if (
        (tab.name == "order0" && !this.cur_order_0) ||
        (tab.name == "order1" && !this.cur_order_1) ||
        (tab.name == "order2" && !this.cur_order_2)
      ) {
        this.get_order_list(
          // function(that) {
          //   that.cur_order_opt = that.cur_order_opt_2;
          // }
        );
      }
    },
    close_my_modal() {
      this.$refs["my-modal"].hide();
    },
    row_clicked_event(row, column, event) {
      if (!hasUtilityAuth('SNManagementEdit')) return;
      return;
      // console.log("row_clicked_event", row, event, column);
      this.cur_product = JSON.parse(JSON.stringify(row));
      if (
        this.cur_product.SerialNumber &&
        this.cur_product.SerialNumber.length > 0
      ) {
        this.cur_SN = JSON.parse(this.cur_product.SerialNumber);
      } else {
        this.cur_SN = [];
      }
      this.$refs["my-modal"].show();
    },
    add_prod_sn() {
      for (var i = 0; i < this.cur_SN.length; i++) {
        if (this.cur_newSN == this.cur_SN[i]) {
          return;
        }
      }
      this.cur_SN.unshift(this.cur_newSN);
    },
    remove_product_sn(sn) {
      for (var i = 0; i < this.cur_SN.length; i++) {
        if (this.cur_SN[i] == sn) {
          this.cur_SN.splice(i, 1);
        }
      }
    },
    async form_submit(event) {
      if (event) {
        event.preventDefault();
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;

      bodyFormData.append("IDn", this.cur_product.IDn);
      bodyFormData.append("SerialNumber", JSON.stringify(this.cur_SN));
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_department_IDn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
      }
      if (this.cur_category_IDn) {
        bodyFormData.append("CategoryIDn", this.cur_category_IDn);
      }
      if (this.cur_name) {
        bodyFormData.append("Name", this.cur_name);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      axios({
        method: "post",
        url: "/Api/Web/Inventory/sn_management/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.productlist = rt.data.products;
              that.product_total = rt.data.product_total;
              that.close_my_modal();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_product_list_delay() {
      if (this.cur_department_IDn) {
        this.cur_categorylist = this.departmentlist[this.cur_department_IDn]["Categorys"];
      } else {
        this.cur_categorylist = this.categorylist;
      }
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_product_list();
      }, 400);
    },
    department_change() {
      this.cur_category_IDn = 0;
      this.get_product_list_delay();
    },
    async get_product_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_department_IDn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
      }
      if (this.cur_category_IDn) {
        bodyFormData.append("CategoryIDn", this.cur_category_IDn);
      }
      if (this.cur_name) {
        bodyFormData.append("Name", this.cur_name);
      }
      if (this.cur_sn_search) {
        bodyFormData.append("SN", this.cur_sn_search);
      }
      if (this.cur_sn_status) {
        bodyFormData.append("Status", this.cur_sn_status);
      }
      if (this.cur_list_opt) {
        bodyFormData.append("opt", this.cur_list_opt);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/sn_management",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
              that.categorylist = rt.data.categorys;
              if (that.cur_department_IDn) {
                that.cur_categorylist = that.departmentlist[that.cur_department_IDn]["Categorys"];
              } else {
                that.cur_categorylist = that.categorylist;
              }
              if (rt.data.products) that.productlist = rt.data.products;
              if (rt.data.sns) that.snlist = rt.data.sns;
              (that.storelist = rt.data.stores),
                (that.product_total = rt.data.total);
              that.per_page = rt.data.per_page;
              // that.form_cancel();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_order_list(func = function() {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      // console.log("get_order_list",
      // this.cur_order_opt_1,
      // this.cur_order_opt_2,
      // this.cur_sn
      // );

      if (this.cur_order_opt_1) {
        bodyFormData.append("order_opt_1", this.cur_order_opt_1);
      }
      if (this.cur_order_opt_2) {
        bodyFormData.append("order_opt_2", this.cur_order_opt_2);
      }
      for (let p in this.cur_sn) {
        if (
          p == "InboundOrderType" ||
          p == "InboundIDn" ||
          p == "InboundOrderIDn_1" ||
          p == "InboundOrderIDn_2" ||
          p == "OutboundOrderType" ||
          p == "OutboundIDn" ||
          p == "OutboundOrderIDn_1" ||
          p == "OutboundOrderIDn_2"
        ) {
          bodyFormData.append(p, this.cur_sn[p]);
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/sn_management/load_order",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (that.cur_order_opt_2 == "order1") {
                that.cur_order_type_1 = rt.data.prefix;
                that.cur_order_1 = rt.data.order;
                that.cur_order_product_list_1 = rt.data.products;
              }
              else if (that.cur_order_opt_2 == "order2") {
                that.cur_order_type_2 = rt.data.prefix;
                that.cur_order_2 = rt.data.order;
                that.cur_order_product_list_2 = rt.data.products;
              } else {
                that.cur_order_opt_2 == "order0";
                that.cur_order_type_0 = rt.data.prefix;
                that.cur_order_0 = rt.data.order;
                that.cur_order_product_list_0 = rt.data.products;
              }
              // that.$refs["order-modal"].show();
              // that.order_modal_show = true;
              func(that);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      this.cur_list_opt = "detail";
      return this.get_product_list();
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.form-group {
  margin-bottom: 0.5rem;
}
#order-modal .modal-title {
  font-size: 2rem !important;
}
#order-modal .modal-body {
  padding-top: 0;
  padding-bottom: 0;
}
.thead-light th .cell {
  text-align: center;
}
</style>
